import React from 'react';

import { Graphql } from '@models/graphql';

type GoogleJobScriptProps = {
  node: Graphql;
};

export default function GoogleJobScript({
  node
}: GoogleJobScriptProps): React.ReactElement {
  const job = node;

  const formatDate = (date: string | null) => {
    const currentDate = date ? new Date(`${date}`) : new Date();
    // Get the year, month, and day components.
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };

  const getData = () => {
    const location = job.relationships?.location?.name;
    const { modality } = job;
    const created = job.created || '';
    const summary = job.wysiwyg?.processed || job.wysiwyg?.summary;

    const information: any = {
      '@context': 'https://schema.org/',
      '@type': 'JobPosting',
      title: job.title,
      description: summary,
      datePosted: formatDate(created),
      employmentType: 'CONTRACTOR',
      hiringOrganization: {
        '@type': 'Organization',
        name: 'Rootstack',
        sameAs: 'https://rootstack.com',
        logo: 'https://cms.rootstack.com/sites/default/files/2024-02/logo-rootstack.png'
      }
    };

    if (location) {
      information.jobLocation = {
        '@type': 'Place',
        address: {
          '@type': 'PostalAddress',
          addressCountry: location
        }
      };
    }

    if (modality === 'remote') {
      // Use this property to specify that the job is a Work from home job.
      information.jobLocationType = 'TELECOMMUTE';

      // Add restriction for remote inside selected country.
      if (location) {
        information.applicantLocationRequirements = {
          '@type': 'Country',
          name: location
        };
      }
    }

    return JSON.stringify(information);
  };

  return job ? (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: getData() }}
    />
  ) : (
    <></>
  );
}
