import React from 'react';
import { Graphql } from '@shared/models/graphql';
import { graphql } from 'gatsby';

import { isContentTypeHero } from '@core/helpers/hero';
import ParagraphComponent from '@core/helpers/paragraph-helpers';
import DomPurify from "@core/helpers/dom-purify";

import Breadcrumb from '@shared/breadcrumbs/breadcrumbs';
import GoogleJobScript from "@shared/google-job/google-job";

import UseForm from '@modules/apply_form//utils/use-form';
import TalentFormApply from '@modules/apply_form/forms/talent-apply-form';

import './job.scss';

const { job: crumbs } = require('@config/breadcrumbs');

type JobProps = {
  language: string;
  data: {
    job: Graphql;
  };
};

export default function JobPage({
  data,
  language
}: JobProps): React.ReactElement {
  const { job } = data;
  const paragraphs = job?.relationships?.paragraphs;
  const department = job?.relationships?.department?.name;
  const content = paragraphs?.map((node) =>
    ParagraphComponent({ ...node, path: job.path }, language)
  );
  let hero = null;
  if (paragraphs && paragraphs.length && content?.length) {
    hero = isContentTypeHero(paragraphs[0]) ? content.shift() : <></>;
  }

  return (
    <>
      <GoogleJobScript node={ job } />
      {hero || <></>}
      <Breadcrumb crumbs={crumbs.concat({ label: job.title, link: '#' })} />
      <div className="container">
        <span className="job-page--department my-5 d-inline-block btn-primary">
          {department}
        </span>
        { job?.wysiwyg?.processed && (
          <div className="wysiwyg mt-3 mb-3">
            <DomPurify text={job?.wysiwyg?.processed} />
          </div>
        )}
      </div>
      {content}
      <div className="job-page--form background-light-blue-two py-4 py-lg-6">
        <div className="container px-lg-5">
          <UseForm showLoading={false}>
            <TalentFormApply language={language} />
          </UseForm>
        </div>
      </div>
    </>
  );
}

export const query = graphql`
  query ($id: String!) {
    job: nodeJob(id: { eq: $id }) {
      id
      title
      type: field_crm_type_key
      created
      wysiwyg: body {
        summary
        processed
      }
      path {
        alias
        langcode
      }
      modality: field_job_modality
      relationships {
        department: field_job_department {
          id
          name
        }
        location: field_job_location {
            id
            name
        }
        paragraphs: field_content_main {
          type: __typename
          ...CtaParagraph
          ...HeroParagraph
          ...HeroSliderParagraph
          ...BannerParagraph
          ...BannerTalentParagraph
          ...BannerAdvParagraph
          ...QuoteSliderParagraph
          ...QuoteParagraph
          ...IntroTextParagraph
          ...IntroBlockParagraph
          ...TimelineParagraph
          ...CardsParagraph
          ...BoxesParagraph
          ...GridParagraph
          ...ClientsParagraph
          ...TechnologiesParagraph
          ...TabsParagraph
          ...TableParagraph
          ...WysiwygParagraph
          ...ViewParagraph
          ...FeaturedContentParagraph
          ...BannerFormParagraph
          ...BannerTalentApplyParagraph
          ...EmergencyModalParagraph
          ...BannerVideoParagraph
          ...WebFormParagraph
          ...CollageParagraph
          ...FabParagraph
          ...ImageSliderParagraph
          ...OfficesParagraph
        }
      }
    }
  }
`;
